import {
    Aggregate, ColumnDirective, ColumnsDirective, CommandColumn,
    Edit,
    ExcelExport,
    Filter, FilterSettingsModel, GridComponent, Inject, Page, PdfExport,
    Resize,
    Toolbar
} from "@syncfusion/ej2-react-grids";
import React, { useCallback, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { loadFromApi } from "../../../api/baseApi";
import {useCommonGridOptions } from "../../../utils/gridUtils";
import { camelCaseToString } from "../../../utils/stringUtils";
import LoadingDisplay from "../../common/LoadingDisplay";
import { GridViewContext } from "../../GridViewService";
import { getDeposit, getDepositSummary } from "../../../api/depositApi";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { useNavigation } from "../../../hooks/useNavigation";
import { topLevelPages } from "../../../pages";
import { AuthContext } from "../../../authentication/authContext";

export const DepositPage = () => {

    const { addToast } = useToasts();
    const { currentView } = React.useContext(GridViewContext);
    const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );
    
    const [depositHistory, setDepositHistory] = useState([]);
    const [depositSummary, setDepositSummary] = useState([]);
    const [updatedDepositSummary, setUpdatedDepositSummary] = useState([]);
    const [showInfoDialog, setShowInfoDialog] = useState(false);
    const [infoRow, setInfoRow] = useState<any>();    
    const [updatedIntent, setUpdatedIntent] = useState([]);
    const [columns, setColumns] = useState<any>([]);
    const {  loggedInUser} = React.useContext(AuthContext);
    const isAchCreditor = loggedInUser?.creditorId != "77" ? true : false;
    const goToPage = useNavigation();
        useEffect(()=> {
            if(loggedInUser && !isAchCreditor && !loggedInUser?.role?.includes('Superuser')){
                goToPage(topLevelPages.scheduledPayments);
            }
        }, [])
    useEffect(() => {
        let data: any  = {};
        const columns = Object.values(depositHistory)[0] ? Object.keys(Object.values(depositHistory)[0]) : [];
        let newColumns = columns.length > 0 && columns.filter((column)=>((column !='Cp_Account_Number' && column !='Cp_Settlement_ID' &&  column !='Cp_Payment_Id')));
        setColumns(newColumns)
        depositHistory.map((row : any)=> {
            let amnt = Number(row.amount?.replace('$', ''))
            if(data.hasOwnProperty(row.bankCode)){                
                data[row.bankCode] =amnt + data[row.bankCode]
            }
            else {
                data[row.bankCode] = amnt
            }
        })
        setUpdatedIntent(data);

    }, [depositHistory]);
    let dataBound = () =>{
        if(gridOptions.ref.current && currentView === 2)
        {
                gridOptions.ref.current.autoFitColumns([])
        }
    }
    
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();

    const handleDateChange = (args?: any) => {
        if (args.startDate && args.endDate) {
            let startDate = new Date(args.startDate);
            let endDate = new Date(args.endDate);
            setStartDate(`${startDate.getMonth()+1}/${startDate.getDate().toString().length === 1 ? '0'+startDate.getDate() : startDate.getDate()}/${startDate.getFullYear()}`)
            setEndDate(`${endDate.getMonth()+1}/${endDate.getDate().toString().length === 1 ? '0'+endDate.getDate() : endDate.getDate()}/${endDate.getFullYear()}`)
        }
    };

    const runDeposit = () => {
        setLoading(true)
        let vendorId= localStorage.getItem('id');
        const DepositPageApiWrapper = loadFromApi(setApiError);
        const loadCompanyPageData = async () => {
            await DepositPageApiWrapper(()=>getDeposit(vendorId ?? 0, startDate, endDate), setDepositHistory);
            await DepositPageApiWrapper(()=>getDepositSummary(vendorId ?? 0, startDate, endDate), setDepositSummary);
            setLoading(false) ;
        };               
        loadCompanyPageData();  
    }

    useEffect(()=>{
        if(depositSummary.length > 0){
            let updatedDepositSummary: any = [];
            updatedDepositSummary[0] = depositSummary?.filter((code: any)=> code?.reliantBankCode === 'Total Deposits')?.[0];
            depositSummary.forEach((code : any)=>{
                if(code?.reliantBankCode !== 'Total Deposits'){
                    updatedDepositSummary.push(code);
                }
            });
            setUpdatedDepositSummary(updatedDepositSummary);
        }
        
    },[depositSummary])
    var exportFileName = 'Deposit History_' + startDate + '-' + endDate;
    const gridOptions: any = useCommonGridOptions(exportFileName);
    const {allowExcelExport, allowPdfExport, toolbar, toolbarClick, pdfQueryCellInfo, excelQueryCellInfo} = gridOptions;
    const filterOptions: FilterSettingsModel = {
        type: 'Excel'
    };
    const infoTemplate = ()=>{
        return <div className="deposit info-form">
            <div  className="deposit-field">Account Name: <strong>{infoRow?.accountName}</strong></div>
            <div  className="deposit-field">Routing Number: <strong>{infoRow?.Routing}</strong></div>
            <div  className="deposit-field">Account Number: <strong>{infoRow?.accountNumber}</strong></div>
            <div  className="deposit-field">Bank Name: <strong>{infoRow?.bankName}</strong></div>
            <div  className="deposit-field">Reliant Bank Code: <strong>{infoRow?.reliantBankCode}</strong></div>
            <div  className="deposit-field">Deposit Amount: <strong>{infoRow?.depositAmount}</strong></div>    
        </div>
    }
        return <>   
        <div className="date-picker">
        <label>Date Range:</label>
        <DateRangePickerComponent
            id="date-range"
            placeholder="Select Date Range"
            change={handleDateChange}
            max = {new Date(new Date().setDate(new Date().getDate() - 1))}
            strictMode={true}
        />
        <button onClick={runDeposit}>Run Deposit</button>
    </div>
        
         { 
            loading  ? (
            <LoadingDisplay />
        ) : <> 
        {updatedDepositSummary.length > 0 &&<h2>Deposit Summary</h2>  }        
        <div className="summary-block">
            {updatedDepositSummary?.map((code: any, index: number) => {
                return <div key={index} className={`card ${updatedIntent[code] ? '' : ''}`}>
                    <h3>{code.reliantBankCode}
                    {code.reliantBankCode !== 'Total Deposits' && <span className="e-icons e-circle-info" onClick={() => {
                      setShowInfoDialog(true);   
                      setInfoRow(code);                 
                  }}/>}</h3>
                  <p>{code.depositAmount ?? 0.00}</p>
              </div>
           })}
        </div>
        {depositHistory.length > 0 && <div className="deposit-details">
        <h2>Deposit History</h2>
        </div>}
        {  depositHistory.length > 0 && <GridComponent
            allowExcelExport={allowExcelExport}
            pdfQueryCellInfo={pdfQueryCellInfo}
            excelQueryCellInfo={excelQueryCellInfo}
            allowPdfExport={allowPdfExport}
            toolbar={toolbar}
            toolbarClick={toolbarClick}
            width={'1500px'}
            ref={gridOptions.ref}
            allowFiltering={true}
            filterSettings={filterOptions}
            dataBound= { dataBound } 
            dataSource={depositHistory}
            clipMode='EllipsisWithTooltip'
            className="deposit-grid"
    >
             <ColumnsDirective>
                {
                    columns.map((key: string, index: number)=>{ 
                     const header = camelCaseToString(key);
                        return <ColumnDirective
                            key={index}
                            field={key}
                            allowFiltering={true}
                            headerText={header.substring(0,1).toUpperCase()+
                            header.substring(1)} 
                            format={key.toUpperCase() === 'PAYMENT AMOUNT' ? "C2" : ""}
                            visible = {!key.startsWith("Cp_")}
                        >
                        </ColumnDirective>
                    })
                }
            </ColumnsDirective>
        <Inject services={[Page,Aggregate,Edit, CommandColumn, PdfExport, ExcelExport, Toolbar, Filter,Resize]} />
    </GridComponent>}</> }
     {showInfoDialog &&  <DialogComponent
            isModal={true}
            visible={showInfoDialog}
            showCloseIcon={true}
            close={() => {
                setShowInfoDialog(false)
            }}
            width="500px"
            height="auto"
            content = {infoTemplate}        >
        </DialogComponent>
     }
    </>
}

