import { env } from 'process';
import { httpRequest, Urls } from './baseApi';

const BASE_URL = process.env.REACT_APP_CREDITOR_API_URL;

const INTENT_URL =  BASE_URL + `/Intent/IntentToDepositRecords`;
const BulkUpdateUrl = BASE_URL + `/Intent/BulkUpdateIntentRecords`;
const Bank_URL =  BASE_URL + `/CreditorInfo/CreditorAccountInfo`;
const REJECT_URL =  BASE_URL + `/Intent/RejectIntentRecord`;
const  UPDATE_ACCOUNT_URL = BASE_URL + '/Intent/updateAcctNumb';
const  UPDATE_BANK_CODES_URL = BASE_URL + '/Intent/UpdateDepositAcctInfo';
const  UPDATE_AMOUNT_URL = BASE_URL + '/Intent/UpdateDepositAmt';
const DISABLE_CHANGES_URL = BASE_URL + '/Intent/DisableChanges';
const BULK_CORRECTIONS_URL = BASE_URL + '/Intent/BulkUpdateIntentRecords';


export const getIntent = (id: any) => httpRequest<any>(`${INTENT_URL}?creditorId=${id}`, 'get');

export const getIntentByTransactions = (creditorId: any, transactionIds: any) => httpRequest<any>(`${BulkUpdateUrl}?creditorId=${creditorId}`, 'post', transactionIds);

export const getDisableChanges = (achCreditorId: number) => httpRequest<any>(`${DISABLE_CHANGES_URL}?achCreditorId=${achCreditorId}`, 'get');

export const getBankCodes = (id: any) => httpRequest<any>(`${Bank_URL}?creditorId=${id}`, 'get');

export const getRejectData = (data: any) => httpRequest<any>(`${REJECT_URL}`, 'post', data);

export const updateAccountData = (data: any) => httpRequest<any>(`${UPDATE_ACCOUNT_URL}?settlementID=${data.id}&newAcctNumb=${data.account}`,'post', data);

export const bulkCorrections = (creditorId: any, data: any) => httpRequest<any>(`${BULK_CORRECTIONS_URL}?creditorId=${creditorId}`,'put', data);

export const updateBankCodesData = (data: any) => httpRequest<any>(`${UPDATE_BANK_CODES_URL}`,'post', data);
export const updateAmountData = (data: any) => httpRequest<any>(`${UPDATE_AMOUNT_URL}`,'post', data);



